<template>
  <div>
    <v-card v-if="slider" class="mx-auto d-flex flex-column product-card mb-1" width="250" height="260">
      <div v-if="slider.offer" class="label-wrapper text-center primary">
        <div class="off-label white text-center">
          <span class="offLabel--text font-weight-bold " v-text="slider.offer"></span>
        </div>
        <span class="white--text smallFont">تخفیف</span>
      </div>
          <!-- <div class="bookmark-container">
            <v-icon>mdi-bookmark-outline</v-icon>
            <v-icon class="primaryText--text">mdi-bookmark</v-icon>
          </div> -->
      <div @click="productDetail(slider.id)" >
        <product imageSize="110" :slider = 'slider'/>
      </div>
      <div class="mt-auto button-container" @click="openOrder">
        <basket v-if="!addToBasket" :slider = 'slider'/>
      </div>
      <div v-if="addToBasket" class="quantity-btn button-container">
        <select-btn v-if="slider.Is_Bulk == 0 || !slider.Is_Bulk" :slider = 'slider' @show= 'show' :orders = 'orders'/>
        <overflow-btn v-if="slider.Is_Bulk == 1" :slider = 'slider' @show= 'show' :orders = 'orders' color="productBtn"/>
      </div>
    </v-card>
  </div>
</template>
<style scoped>
.v-card > *:first-child:not(.v-btn):not(.v-chip), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip){
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
</style>
<script>
import product from './productsCard'
import basket from '../buttons/basketBtnOutline'
import SelectBtn from '../buttons/selectBtn.vue'
import OverflowBtn from '../buttons/overflowBtn.vue'
import { mapState } from 'vuex'
import router from '@/router'
export default {
  components: {
  product,
  basket,
  OverflowBtn,
  SelectBtn
},
props: ['slider'],
data: () => ({
  addToBasket: false,
  orders: {
    order: {},
    orderd: false
  },
  attrs: {
    boilerplate: false,
    elevation: 0,
  },
}),
    computed: {
      ...mapState({
        basket: state => state.basket.basket
      })
    },
    watch: {
      basket: {
        handler: function () {
          for (const pro of this.basket) {
          if (pro.id == this.$props.slider.id) {
            this.orders.orderd = true
            this.orders.order = pro
            this.addToBasket = this.orders.orderd
          }
        }
        }
      }
    },
    methods: {
      show (show) {
        this.addToBasket = show
      },
      openOrder () {
        if (this.$props.slider.onhand != 0) {
          this.addToBasket = true
        }
      },
      productDetail (productID) {
        router.push(`/product_detail/${productID}`)
      }
    }
}
</script>
 