<template>
  <div>
    <v-row class="d-flex justify-center align-center mt-1">
      <v-list-item-avatar tile  class="mx-auto" :size="imageSize"  color="white">
        <v-img :src="slider.image"/>
      </v-list-item-avatar>
    </v-row>
    <v-row class="mr-2">
      <div class="title-container subheaderFont--text">
        <div v-text="slider.title.slice(0,70)"><span class="medium-font" v-if ="slider.title > slider.title.slice(0,70)">...</span></div>
      </div>
    </v-row>
    <v-row v-if="slider.Is_Bulk == 1" class="my-1 d-flex align-center">
      <v-col cols="5" class="justify-start">
        <span  class="subheaderFont--text medium-font mr-2">هر <span v-text="slider.unit_title"></span></span>
      </v-col>
      <v-col cols="7">
        <span class="subheaderFont--text medium-font" v-text="slider.priceFormate"></span>
      </v-col>
    </v-row>
    <v-row v-if="slider.Is_Bulk == 1" class="mb-1 mr-2 d-flex justify-center align-center">
      <span class="error--text medium-font text-decoration-line-through font" v-if="slider.price != slider.price_gross && slider.price_gross != 0"  v-text="slider.priceGrossFormate"></span>
    </v-row> 
    <v-row class="my-1 d-flex align-center">
      <v-col cols="5" class="justify-start pt-1">
        <span class="subheaderFont--text mr-2 medium-font">قیمت:</span>
      </v-col>
      <v-col cols="7" class="pt-1">
        <span v-if="slider.Is_Bulk == 1" class="subheaderFont--text medium-font" v-text="slider.price_format_pack"></span>
        <span v-if="slider.Is_Bulk != 1" class="subheaderFont--text medium-font" v-text="slider.priceFormate"></span>
      </v-col>
    </v-row>
    <v-row v-if="slider.Is_Bulk != 1" class="mb-1 mr-2 d-flex justify-center align-center">
      <span class="error--text medium-font text-decoration-line-through font" v-if="slider.price != slider.price_gross && slider.price_gross != 0"  v-text="slider.priceGrossFormate"></span>
    </v-row>   
  </div>
</template>
<script>
export default {
  props: ['imageSize', 'slider'],
  mounted () {
    caches.open('images').then(cache => {
      cache.add(this.$props.slider.image)
    })
  }
}
</script>
