<template>
  <div id="category-slider">
    <div class="d-flex justify-center">
    <v-slide-group multiple>
      <v-slide-item v-for="list in blocks"
        :key="list.id">
        <!-- <v-btn 
        @click="getList(list.id)"
        outlined 
        :color="fild_active == 'all' ? '': 'primary'" class="button mx-1" active-class="primary white--text">
        <v-avatar size="25" class="ml-1">
          <v-img :src="list.banner"/>
        </v-avatar>
        {{list.title}}
        </v-btn> -->
        <div @click="getList(list.id)" :class="list.id == active_block ? 'active_category':''" class="d-flex flex-column justify-center align-center cursor-pointer px-3 py-1">
          <v-avatar size="50" class="ml-1">
            <v-img :src="list.banner"/>
          </v-avatar>
          <span class="medium-font mt-1 block-name">{{list.title}}</span>
        </div>
      </v-slide-item>
    </v-slide-group>
    </div>
    <cards-slider :options="options" v-for="block in blockList" :key="block.id" :block="block" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { blockList } from '../../models/home'
import CardsSlider from './cardsSlider.vue'
export default {
  components: {
    CardsSlider
  },
  data:() => ({
    active_block: '',
    options: {
      rewind : true,
      trimSpace: true,
      waitForTransition: false,
      swipeDistanceThreshold: 200,
      width: '100%',
      perPage: 4,
      arrows: false,
      drag: true,
      pagination: false,
      type   : 'slide',
      gap: '0.5rem',
      perMove: 1,
      start: 0,
      direction: 'rtl',
      padding: {
        left : '30px',
        right: '30px',
      },
      breakpoints: {
        320: {
          width  : '100%',
          // gap    : '0.5rem',     
          perPage: 1,
        },
        350: {
          width  : '100%',
          // gap    : '0.5rem',     
          perPage: 1,
        },
        375: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 1.5,
        },
        380: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 2,
        },
        420: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 2,
        },
        500: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 2.2,
        },
        560: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 2,
        },
        669: {
          width  : '100%',
          gap    : '0.5rem',     
          perPage: 3,
        },
        700:{
          width  : '100%',
          perPage:3,
          gap    : '0.5rem', 
        },
        770:{
          width  : 750,
          perPage:3,
          gap    : '0.5rem', 
        }
      },
    },
  }),
  computed: {
    ...mapState({
      blocks: state => state.home.blocks,
      blockList: state => state.home.blockList,
    })
  },
  watch:{
    blocks: {
      handler: function () {
        this.active_block = this.blocks[0].id
      }
    }
  },
  methods:{
    getList(id) {
      this.active_block = id
      blockList(id)
    }
  }
}
</script>