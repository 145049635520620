<template>
<div>
  <div class="mt-4 mb-2 mx-3">
    <span class="base-font primaryText--text font-weight-bold" v-text="block.block_template.title"></span>
  </div>
  <div id="product-carousel" class="d-flex flex-column justify-center align-center">
    <splide v-if="slides.length > 0" :options= "options" :slides = 'slides'>
      <splide-slide v-for="slide  in slides" :key="slide.id">
        <product :slider = 'slide'/>
      </splide-slide>
    </splide>
    <splide v-if="slides.length == 0" :options= "options">
      <splide-slide>
        <v-skeleton-loader
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
    </splide>
  </div>
</div>
</template>
<style lang="scss" scoped>
.v-menu__content.theme--light.menuable__content__active{
  z-index: 0 !important;
}
</style>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import product from '../cards/product'
import { mapState } from 'vuex'
import { blockList, viewblock } from '../../models/home'
import { viewbasket_products } from '../../../Basket/models/Basket'
import store from '@/store';
import { search_product_tag } from '@/views/shop/search/models/search';
export default {
    components: {
    Splide,
    SplideSlide,
    product,
  },
  props: ['options', 'block'],
  computed: {
    ...mapState({
      productBlock: state => state.home.blockProduct
    }),
    slides () {
      let products = []
      for (const product of this.productBlock) {
        if (product.parentID == this.$props.block.id) {
          products = product.products
          return products
        }
      }
      return products
    }
  },
  data: () => ({
    blockID: '',
    items: [],
    benched: 0,
  }),
  methods: {
    // select (select, index) {
    //   store.dispatch('selected_list', {index: index, block_id: this.$props.block.id})
    //   viewblock(select.id,this.$props.block.id)
    //   viewbasket_products()
    // },
    // show_more () {
    //   let filters = localStorage.getItem('filter_search')
    //     if (filters) {
    //       filters = JSON.parse(filters)
    //       filters.page = 1
    //     } else {
    //       filters = {}
    //       filters.page = 1
    //     }
    //   switch (this.showDefult.filter_type) {
    //     case '1':
    //       filters.block_id = this.showDefult.id
    //       filters.title = {title: this.showDefult.title, value: 'block_id'}
    //       localStorage.setItem('filter_search', JSON.stringify(filters))
    //     setTimeout(() => {
    //       search_product_tag(filters)
    //     }, 500);
    //       break;
    //       case '2':
    //     filters.group = this.showDefult.filter_search
    //     filters.title = {title: this.showDefult.title, value: 'group'}
    //     localStorage.setItem('filter_search', JSON.stringify(filters))
    //     setTimeout(() => {
    //       search_product_tag(filters)
    //     }, 500);
    //       break;
    //        case '3':
    //     filters.tags = this.showDefult.filter_search
    //     filters.title = {title: this.showDefult.title, value: 'tags'}
    //     localStorage.setItem('filter_search', JSON.stringify(filters))
    //     setTimeout(() => {
    //       search_product_tag(filters)
    //     }, 500);
    //       break;
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
.grid{
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 95%;
  align-items: center;
}
</style>